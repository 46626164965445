import React, { useState } from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import axios from "axios"

//import css
import "./../../styles/upcoming-integrations.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const Form = () => {
  const [tool, setTool] = useState({
    slack: true,
    webhooks: true,
    pagerduty: true,
    telegram: true,
    discord: true,
    opsgenie: true,
    twilio: true,
    vonage: true,
    zapier: false,
    pushbullet: false,
    microsoftteam: false,
    googlechat: false,
    pushover: false,
    twitter: false,
  })

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [email, setEmail] = useState("")
  const [formResponse, setFormResponse] = useState({
    status: "warning",
    message: "",
  })

  const integrations = [
    {
      name: "Slack",
      handlename: "slack",
      description:
        "Slack messages are a great way to inform the entire team of a downtime.",
      icon: "slack.svg",
      supported: true,
    },
    {
      name: "Webhooks",
      handlename: "webhooks",
      description:
        "For advanced alerting you can setup webhooks to your own system or app.",
      icon: "webhooks.svg",
      supported: true,
    },
    {
      name: "Telegram",
      handlename: "telegram",
      description: "Telegram messages are a great way how to stay alerted.",
      icon: "integration-telegram.svg",
      supported: true,
    },
    {
      name: "Discord",
      handlename: "discord",
      description:
        "Get important monitor status updates in your Discord messages.",
      icon: "integration-discord.svg",
      supported: true,
    },
    {
      name: "PagerDuty",
      handlename: "pagerduty",
      description:
        "Send up & down events and auto-resolve your incidents in PagerDuty.",
      icon: "pagerduty.png",
      supported: true,
    },
    {
      name: "Opsgenie",
      handlename: "opsgenie",
      description: "Receive Odown alerts in your OpsGenie account",
      icon: "opsgenie.png",
      supported: true,
    },
    {
      name: "Twilio",
      handlename: "twilio",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-twilio.svg",
      supported: true,
    },
    {
      name: "Vonage(Nexmo)",
      handlename: "vonage",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-vonage.svg",
      supported: true,
    },
    {
      name: "Zapier",
      handlename: "zapier",
      description: "Integrate your Zapier account to get alerted right away.",
      icon: "integration-zapier.svg",
      supported: false,
    },
    {
      name: "Pushbullet",
      handlename: "pushbullet",
      description:
        "Get instant alerts via mobile push notifications in Pushbullet Android app.",
      icon: "integration-pushbullet.svg",
      supported: false,
    },
    {
      name: "Microsoft Team",
      handlename: "microsoftteam",
      description:
        "Get notifications inside your MS Teams app to alert everyone in the group.",
      icon: "integration-microsoft-teams.svg",
      supported: false,
    },
    {
      name: "Google Chat",
      handlename: "googlechat",
      description:
        "Get notifications into the Google Chat. Just copy the room URL to Odown.",
      icon: "integration-hangouts.svg",
      supported: false,
    },
    {
      name: "Pushover",
      handlename: "pushover",
      description:
        "Get instant alerts via mobile push notifications in Pushover app.",
      icon: "integration-pushover.svg",
      supported: false,
    },
    {
      name: "Twitter",
      handlename: "twitter",
      description:
        "Let us send you a private message on Twitter about your outages.",
      icon: "integration-twitter.svg",
      supported: false,
    },
  ]

  /**
   *
   * @param {*} event
   */
  const handleCheckboxInputChange = event => {
    const target = event.target
    const value = target.checked
    const name = target.name

    setTool(prevState => ({ ...prevState, [name]: value }))
  }

  /**
   *
   * @param {*} event
   */
  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    let isValid = false
    if (value.match(emailRegex) !== null) {
      isValid = true
    }

    setIsValidEmail(isValid)
    setEmail(value)
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  const handleSubmit = event => {
    event.preventDefault()

    if (isValidEmail === false) {
      setFormResponse({
        status: "danger",
        message:
          "Please check your e-mail address in order to ensure you receive the latest update of tool integrations.",
      })
      return
    }
    let toolChosen = 0
    Object.keys(tool).map(item => {
      if (tool[item] === true) toolChosen++
    })

    if (toolChosen <= 2) {
      setFormResponse({
        status: "danger",
        message:
          "Please choose at least one integration so that we can notify you of its availability as soon as possible ",
      })
      return
    }

    axios
      .post("https://api.odown.io/v1/upvote/upcoming-integrations", {
        email: email,
        zapier: tool["zapier"],
        pagerduty: tool["pagerduty"],
        telegram: tool["telegram"],
        discord: tool["discord"],
        pushbullet: tool["pushbullet"],
        microsoftteam: tool["microsoftteam"],
        googlechat: tool["googlechat"],
        pushover: tool["pushover"],
        twitter: tool["twitter"],
        opsgenie: tool["opsgenie"],
      })
      .then(res => res.data)
      .then(response => {
        setFormResponse({
          status: "success",
          message: response.message,
        })
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          setFormResponse({
            status: "danger",
            message: error.response.data,
          })
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
        // console.log(error.config);
      })
  }

  return (
    <>
      <div className="upcoming-integration-wrapper">
        <div className="container">
          <div className="form-upvote">
            <h1>Upcoming integrations</h1>
            <p>Sign up to be notified about your favorite next integrations.</p>
            <div
              className={`response ${formResponse.status} ${
                formResponse.message === "" ? "hide" : ""
              } `}
            >
              {formResponse.message}
            </div>
            {formResponse.status !== "success" && (
              <form onSubmit={handleSubmit}>
                <div className="available-integrations">
                  {integrations.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`intg-item_wrapper ${
                          item.supported ? "supported" : ""
                        } ${tool[item.handlename] ? "selected" : ""}`}
                      >
                        <label className="intg-item">
                          <input
                            type="checkbox"
                            name={item.handlename}
                            checked={tool[item.handlename]}
                            onChange={handleCheckboxInputChange}
                            disabled={item.supported}
                          />
                          <div className="intg--icon">
                            <img src={`/${item.icon}`} alt={item.handlename} />
                          </div>
                          <div className="intg--name">
                            {item.name}
                            {item.supported && (
                              <span className="supported-tool">
                                Already supported
                              </span>
                            )}
                          </div>
                          <div className="intg--desc">{item.description}</div>
                        </label>
                      </div>
                    )
                  })}
                </div>
                <div className="form-submit-fields">
                  <p className="field-label">
                    Share your email so Odown can send you product updates.
                  </p>
                  <input
                    type="text"
                    className="field"
                    name="email"
                    placeholder="your@email.com"
                    value={email}
                    onChange={handleInputChange}
                  />
                  <button className="btn" type="submit">
                    Notify Me
                  </button>
                  <p className="note-label">You can unsubscribe at any time.</p>
                </div>
              </form>
            )}
            <div
              className={`response ${formResponse.status} ${
                formResponse.message === "" || formResponse.status === "success"
                  ? "hide"
                  : ""
              } `}
            >
              {formResponse.message}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

/**
 *
 * @param {*}
 */
const upcomingIntegrations = ({ data }) => (
  <Layout>
    <SEO
      title="Upcoming uptime integrations"
      description="Sign up to be notified about your favorite next uptime integrations at Odown"
      pathname={`/integrations/upcoming`}
    />
    <Form />

    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default upcomingIntegrations
